window.addEventListener('load', function() {
  var form = document.getElementById('password-grant-type-form')
  var usernameInput = document.getElementById('password-grant-type-username')
  var passwordInput = document.getElementById('password-grant-type-password')

  // Functions validateEmpty and validateLoginForm are general functions and
  // should be moved to a helper file so they can be use for other forms,
  // unfortunately webpack import does not exports functions directly so most
  // likely a config change is required.

  var validateEmpty = function(form, input, text){
    if(input.value.length == 0){
      input.setCustomValidity(text)
    }else{
      input.setCustomValidity('');
    }
    form.classList.remove('was-validated');
  };

  var validateLoginForm = function(form, usernameInput, passwordInput){
    passwordInput.addEventListener('input', function(event){
      form.classList.remove('was-validated');
    });

    usernameInput.addEventListener('input', function(event){
      form.classList.remove('was-validated');
    });

    form.addEventListener('submit', function(event) {
      form.classList.remove('was-validated');
      validateEmpty(form, usernameInput, 'empty username');
      validateEmpty(form, passwordInput, 'empty password');
      if(form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  };

  if (form) {
    validateLoginForm(form, usernameInput, passwordInput)
  }

}, false);
