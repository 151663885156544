import Rails from "@rails/ujs"

import 'jquery'
import 'popper.js'
import 'bootstrap'
import 'packs/reset_password.js'
import 'packs/authentications.js'
import 'packs/password_grant_type.js'
import 'css/application'

require.context('../images', true)

Rails.start()
