window.addEventListener('load', function() {
  var form = document.getElementById('reset_password_form')
  var password = document.getElementById('reset_password_input')
  var passwordConfirmation =
    document.getElementById('reset_password_confirmation_input')
  var passwordRegex =
    new RegExp(/^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*\.])[\w!@#$%^&*\.]{6,}$/)

  var validatePassword = function(){
    if(!passwordRegex.test(password.value)){
      password.setCustomValidity('password not meet requirements')
    }else{
      password.setCustomValidity('');
    }
    form.classList.remove('was-validated');
  };

  var validatePasswordConfirmation = function() {
    if(password.value != passwordConfirmation.value){
      passwordConfirmation.setCustomValidity('passwords do not match')
    }else{
      passwordConfirmation.setCustomValidity('')
    }
  };

  if (form) {
    password.addEventListener('input', function(event){
      form.classList.remove('was-validated');
    });

    passwordConfirmation.addEventListener('input', function(event){
      form.classList.remove('was-validated');
    });

    form.addEventListener('submit', function(event) {
      form.classList.remove('was-validated');
      validatePassword();
      validatePasswordConfirmation();
      if(form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  }

}, false);
