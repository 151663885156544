window.addEventListener('load', function() {
  var form = document.getElementById('authentications-form')
  var usernameInput = document.getElementById('authentications-username')
  var passwordInput = document.getElementById('authentications-password')

  var validateUsername = function(){
    if(usernameInput.value.length == 0){
      usernameInput.setCustomValidity('empty username')
    }else{
      usernameInput.setCustomValidity('');
    }
    form.classList.remove('was-validated');
  };

  var validatePassword = function(){
    if(passwordInput.value.length == 0){
      passwordInput.setCustomValidity('empty password')
    }else{
      passwordInput.setCustomValidity('');
    }
    form.classList.remove('was-validated');
  };
  if (form) {
    passwordInput.addEventListener('input', function(event){
      form.classList.remove('was-validated');
    });

    usernameInput.addEventListener('input', function(event){
      form.classList.remove('was-validated');
    });

    form.addEventListener('submit', function(event) {
      form.classList.remove('was-validated');
      validateUsername();
      validatePassword();
      if(form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  }

}, false);